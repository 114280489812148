.Registration {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.RegisterForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.RegisterRedirect {
    cursor: pointer;
    text-decoration: underline 1px solid;
}

@media screen and (max-height: 720px) {


    .FormBlock__Wrapper {
        margin: 1rem;
        gap: 1rem;
    }

}